import { Router } from '@angular/router'
import { Component, OnDestroy, OnInit,NgZone  } from '@angular/core';
import { User } from '@angular/fire/auth';
import { VersionEvent } from '@angular/service-worker';
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Network } from '@capacitor/network';
import { GetNotifications } from '@ih/app/client/shared/components/notifications/data-access';
import {
  MediaQueriesSyncService,
  ToastService,
  TrackQueriesService,
  TrackQueriesSyncService,
  UpdateService,
  jobHistoryService,
  PushNotificationsService
} from '@ih/app/client/shared/services';
import {
  AuthStateAction,
  LoggedInAction,
  ManuallySetStatus,
  SetStandalone,
  SetStatus,
  SetSynchingStatus,
} from '@ih/app/client/shared/states';
import { ToastController } from '@ionic/angular';
import {
  Actions,
  ofActionCompleted,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';
import { filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';

import { offlineTracker } from './graphql.module';
import { environment } from '../environments/environment';

@Component({
    selector: 'ih-app-root',
    template: ` <ih-app-client-shell-feature></ih-app-client-shell-feature>
  <tour-step-template></tour-step-template> `,
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  updateAvailable$!: Observable<VersionEvent>;
  private ngUnsubscribe = new Subject<void>();
  message = '';
  constructor(
    private readonly store: Store,
    private readonly updateService: UpdateService,
    private readonly toastController: ToastController,
    private readonly trackQueriesService: TrackQueriesService,
    private readonly trackQueriesSyncService: TrackQueriesSyncService,
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private zone: NgZone,
    private router: Router,
    private readonly mediaQueriesSyncService : MediaQueriesSyncService,
    private readonly pushnotificationsService: PushNotificationsService,
  ) {

    const userId$: Observable<string | null> = this.store.select(state => {if(state.me.me) return state.me.me.id});
    // This had to be added after firebase amdin was updated, now doesnt seem neccesary anymore. Kept just in case issue pops up again. 
    // const app = initializeApp(environment.firebase);
    // if (Capacitor.isNativePlatform()) {
    //   initializeAuth(app, {
    //     persistence: indexedDBLocalPersistence
    //   });
    // }
  
    userId$
      .pipe(
        filter(userId => !!userId), 
        take(1) 
      )
      .subscribe(userId => {
        if(!userId) return;
        this.pushnotificationsService.init(environment.firebase.vapidKey, userId);
    });


    this.initializeApp();
    this.updateAvailable$ = this.updateService.available$().pipe(
      tap((evt) => {
        if (evt.type === 'VERSION_READY') {
          this.updateToast();
        }
      }),
      takeUntil(this.ngUnsubscribe),
    );
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'app.informationhub.io';
        const pathArray = event.url.split(domain);

        const appPath = pathArray.pop();
        if (!appPath) return;

        this.router.navigateByUrl(appPath);
      });
    });

  }

  ngOnInit(): void {
    this.updateAvailable$.subscribe();
    this.store.dispatch(new AuthStateAction());
    this.store.dispatch(new SetStandalone(false));
    this.GetNetworkStatus().then((status) => {
      this.store.dispatch(new SetStatus(status.connected));
    });

    // sync tracked queries on load after logged in and when there is internet
    this.actions$
      .pipe(
        ofActionSuccessful(LoggedInAction),
        tap(() => {
          this.GetNetworkStatus().then((status) => {
            if (status.connected) {
              this.trackQueriesService.removeCompletedQueries().then(() => {
                this.trackQueriesService
                  .getTrackedQueriesFromDB()
                  .then((trackedQueries) => {
                    this.trackQueriesSyncService.syncAll(trackedQueries);
                  });
              });
              this.store.dispatch(new GetNotifications());
            } else {
              offlineTracker.close();
            }
          });
        }),
        take(1),
      )
      .subscribe();

    Network.addListener('networkStatusChange', (status) => {
      this.store.dispatch(new SetStatus(status.connected));

      this.manageOfflineTracker(status.connected);
    });

    this.actions$
      .pipe(
        ofActionCompleted(ManuallySetStatus),
        tap((action: any) => {
          this.manageOfflineTracker(!action.action.newStatus);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }


  async GetNetworkStatus() {
    return await Network.getStatus();
  }

  async updateToast() {
    const toast = await this.toastController.create({
      header: 'Update Available',
      message: 'Reload the app to receive the new update.',
      position: 'bottom',
      buttons: [
        {
          text: 'Reload',
          role: 'cancel',
          handler: () => {
            /** Only clear localstorage which contains:
             * - NGXS Storage
             * - Apollo Persisted Cache
             * Dexie tables are not cleared due to possibility of losing data that is not synced
             **/
            // Go to root directory
            this.router.navigate(['/home']);
            localStorage.clear();

            // Wait till routing is done
            setTimeout(() => {
              window.location.reload();
            }, 500);
          },
        },
      ],
    });
    await toast.present();
  }

  manageOfflineTracker(status: boolean) {
    const manualNetwork = this.store.selectSnapshot<boolean>(
      (state) => state.network.manual,
    );

    const synching = this.store.selectSnapshot<boolean>(
      (state) => state.network.synching
    );

    if (status && !manualNetwork) {
      offlineTracker.open();

      if (synching) {
        jobHistoryService.isEmpty().then(async (empty) => {

          if (!empty) {
            this.toastService.showInfo('Submitting jobs...');
            jobHistoryService.autoSync().then((flag) => {
              if (flag)
                jobHistoryService.processMedia().then((mediaIds) => {
                  {
                    this.toastService.showInfo('Jobs executed', 'success');
                    mediaIds.forEach(async mediaId => {
                      try {
                        await this.mediaQueriesSyncService.upload(mediaId);
                      }
                      catch (e) {
                        console.error(e);
                      }
                    });
                  }
                })
              else
                this.toastService.showInfo('Jobs failed', 'danger');
            })
            this.store.dispatch(new SetSynchingStatus(false));
          }
        })
      }

      const authUser = this.store.selectSnapshot<User | null>(
        (state) => state.authentication.user,
      );

      // sync tracked queries on internet connection when there is no queries in queue
      if (authUser && offlineTracker.getQueue().length === 0) {
        this.trackQueriesService.removeCompletedQueries().then(() => {
          this.trackQueriesService
            .getTrackedQueriesFromDB()
            .then((trackedQueries) => {
              this.trackQueriesSyncService.syncAll(trackedQueries);
            });
        });
      }
      this.store.dispatch(new GetNotifications());
    } else {
      offlineTracker.close();
    }
  }

  ngOnDestroy(): void {
    Network.removeAllListeners();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    // if (!this.apiLoaded.closed) {
    //   this.apiLoaded.closed;
    // }
  }

}
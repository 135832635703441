import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  getMetadata,
  UploadTaskSnapshot,
} from '@angular/fire/storage';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { db } from '@ih/app/client/shared/db';
import { StorageService, TablesService } from '@ih/app/client/shared/services';
import {
  Column,
  Data,
  DataRowsResponse,
  DataType,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { lastValueFrom, map, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaQueriesSyncService {

  constructor(
    private readonly storageService: StorageService,
    private readonly tableService: TablesService,
    private readonly apollo: Apollo,
    private readonly store: Store
  ) {}


  /**
   * TODO : Add docs
   */
  async upload(id  = '') {
    let flag = false;
    if(id != '') flag = true;
    // Get stored media queries from db
    await db.trackedMedia.toArray().then(async (fileList) => {

      for (const mediaQueryFile of fileList) {
        let path: string | null = null;
        let prependCuid = false;
        if (mediaQueryFile) {
          // if id specified, only upload that specific file
          if(flag && mediaQueryFile.trackId != id) {
            continue;
          }
          // Set path of where file will be uploaded to and if needs to have
          // prepended id if from forms
          prependCuid =
            !mediaQueryFile?.meta.columnId || !mediaQueryFile?.meta.tableId
              ? false
              : true;
          path = mediaQueryFile?.meta.path;
          if (mediaQueryFile.files) {
            let uploadedFiles = '';

            // Get files inside of query
            const files = Array.from(mediaQueryFile.files);

            for (const file of files) {
              // Assign cuid if needed
              let fileName = '';
              if (prependCuid) {
                fileName = new Date().toISOString() + '_' + file.name;
              } else {
                fileName = file.name;
              }

              const filepath = `${path}/${fileName}`;
              // Upload file
              // Once upload completed, remove from db and insert to table if needed
              await this.storageService
                .upload(filepath, file)
                .then(async (snapshot) => {
                  if (
                    mediaQueryFile.meta.columnId &&
                    mediaQueryFile.meta.tableId
                  ) {
                    uploadedFiles += await this.buildFileUploaded(snapshot);
                  }

                  // Remove completed query from db
                  // await db.trackedMedia.delete(mediaQueryFile.trackId);
                });
            }

            const tableId = mediaQueryFile.meta.tableId;
            const mediaColumnId = mediaQueryFile.meta.columnId;
            const columns$ =  this.getAllColumnsFromTable(tableId);
            const columnName = (await lastValueFrom(columns$)).data.findAllColumns[0].name;
            const findDataRow$ = this.getDataRowWithTrackId(
              'offlineMediaTrackId:' + mediaQueryFile.trackId,
              tableId,
              columnName,
            );
            const findDataRow = await lastValueFrom(findDataRow$);

            await this.getPrimaryKeyColumnIdFromColumns(tableId).then(
              async (primaryKeyColumnId: string | undefined) => {

                const primaryKey = findDataRow?.rows[0].columns.find(
                  (data) => data.columnId === primaryKeyColumnId
                );
                const primaryKeyValue = primaryKey?.value + '';
                const updateMediaColumn$ = this.updateMediaColumnRowValue(
                  tableId,
                  primaryKeyValue,
                  mediaColumnId,
                  uploadedFiles
                );
                await lastValueFrom(updateMediaColumn$);
              }
            );
          }
        }
      }
    });
  }

  // TODO : Remove trailing comma

  async buildFileUploaded(snapshot: UploadTaskSnapshot) {
    const downloadUrl = await getDownloadURL(snapshot.ref);
    const meta = await getMetadata(snapshot.ref);
    return `${meta.name}[${downloadUrl}], `;
  }

  getDataRowWithTrackId(trackId: string, tableId: string | null,columnName : string) {
    type ResultType = { findDataRows: DataRowsResponse };
    const query = gql`
      query FindDataRows(
        $tableId: String!
        $cursor: String
        $limit: Int!
        $orderBy: String!
        $orderByType: DataType!
        $search: String
      ) {
        findDataRows(
          request: {
            tableId: $tableId
            cursor: $cursor
            limit: $limit
            orderBy: $orderBy
            orderByType: $orderByType
            search: $search
          }
        ) {
          rows {
            columns {
              columnId
              value
            }
          }
          cursor
        }
      }
    `;

    const request = {
      tableId: tableId,
      cursor: null,
      limit: 1,
      orderBy: columnName,
      orderByType: DataType.TEXT,
      search: trackId,
    };

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          tableId: request.tableId,
          cursor: request.cursor,
          limit: request.limit,
          orderBy: request.orderBy,
          orderByType: request.orderByType,
          search: request.search,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            return result.data?.findDataRows || null;
          }
        )
      );
  }

  getAllColumnsFromTable(tableId: string | null) {
    return this.apollo
      .watchQuery<{ findAllColumns: Column[] }>({
        query: gql`
          query FindAllColumns($tableId: String!) {
            findAllColumns(request: { where: { tableId: $tableId } }) {
              id
              name
              dataTypeConfiguration
              description
              dataType
              required
              primaryKey
              unique
              autoIncrement
            }
          }
        `,
        variables: {
          tableId,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        take(1),
        tap((result: ApolloQueryResult<{ findAllColumns: Column[] }>) => {
          return result.data?.findAllColumns || null;
        })
      );
  }

  // Get primary key columnid so we know where to search for the primary key in the returned data row
  async getPrimaryKeyColumnIdFromColumns(
    tableId: string | null
  ): Promise<string | undefined> {
    const findAllColumns$ = this.getAllColumnsFromTable(tableId);
    const findAllColumns = await lastValueFrom(findAllColumns$);
    const primaryKeyColumn = findAllColumns.data.findAllColumns.find(
      (column: Column) => column.primaryKey === true
    );
    return primaryKeyColumn?.id;
  }

  updateMediaColumnRowValue(
    tableId: string | null,
    primaryKeyValue: string,
    columnId: string | null,
    value: string
  ) {
    type ResultType = { updateDataColumnValues: Data };

    const row = {
      columns: [
        {
          columnId: columnId,
          value: "'" + value + "'",
        },
      ],
    };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdateDataColumnValues(
            $tableId: String!
            $primaryKeyValue: String!
            $row: DataRowInput!
          ) {
            updateDataColumnValues(
              request: {
                tableId: $tableId
                primaryKeyValue: $primaryKeyValue
                row: $row
              }
            ) {
              rowsAffected
            }
          }
        `,
        variables: {
          tableId: tableId,
          primaryKeyValue: primaryKeyValue,
          row: row,
        },
      })
      .pipe(map((result: any) => result.data?.updateDataColumnValues || null));
  }

  getTrackedMediaFromDB(): Promise<any> {
    return db.trackedMedia.toArray().then((trackedMedia) => {
      return trackedMedia;
    });
  }

  async getTrackedMediaFromDBWithTrackId(trackId: string): Promise<any> {
    const trackedMedia = await db.trackedMedia
      .where('trackId')
      .equals(trackId)
      .toArray()
    return trackedMedia;
  }

  async getTotalFileSize(): Promise<number> {
    let totalSize = 0;
    await this.getTrackedMediaFromDB().then(async (fileList) => {
      for (const mediaQuery of fileList) {
        mediaQuery.files.forEach((file: any) => {
          totalSize += file.size;
        });
      }
    });
    return totalSize;
  }

  async updateTrackedMedia(trackedId: string, data: any) {
    db.trackedMedia.update(trackedId, data);
  }

  async removeFromDB(trackId: string) {
    await db.trackedMedia.delete(trackId);
  }
}

import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage} from '@angular/fire/messaging';
import { BehaviorSubject, firstValueFrom} from 'rxjs';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import {
  FindUniqueNotificationTokenRequest,
  FindAllNotificationTokensRequest,
  DeleteNotificationTokenRequest,
  NotificationToken,
  PlatformType,
  StoreNotificationTokenRequest

} from '@ih/app/shared/apis/interfaces';
import { map, Observable, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';

enum PushNotificationStatus {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied'
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private currentToken = new BehaviorSubject<string | null>(null);
  public currentToken$ = this.currentToken.asObservable();
  vapidKey = '';
  currUserId = '';

  init(key : string, userId: string){
    this.vapidKey = key;
    this.currUserId = userId;

    if (Capacitor.isNativePlatform()) {
      this.requestNativePermission();
    } else {  
      this.registerServiceWorker();
      this.requestWebPermission();
      this.listen();
    }
  }

  constructor(private readonly apollo: Apollo,
    private readonly store: Store,
  ) {}

  async requestNativePermission() {
    try {
      let permStatus = await PushNotifications.checkPermissions();
      let statusReceived : PushNotificationStatus = permStatus.receive as PushNotificationStatus;

      if (statusReceived === PushNotificationStatus.PROMPT) {
        permStatus = await PushNotifications.requestPermissions();
        statusReceived = permStatus.receive as PushNotificationStatus;
      }

      if (statusReceived !== PushNotificationStatus.GRANTED) {
        return;
      }

      await PushNotifications.register();

      await PushNotifications.addListener('registration', async registrationToken => {
      const platform = Capacitor.getPlatform() === 'android' ? PlatformType.ANDROID : PlatformType.IOS
      const token = registrationToken;


      const createTokenRequest = {
        token: token.value,
        userId: this.currUserId,
        notificationType: platform,
      } as StoreNotificationTokenRequest;

        await firstValueFrom(this.storeNotificationToken(createTokenRequest))
      });

      await PushNotifications.addListener('registrationError', err => {
        // console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
        // console.log('Push notification received: ', JSON.stringify(notification));

      });

      // await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      // });
    } catch (error) {
      console.error('Error requesting permission: ', error)
    }
  }

  async requestWebPermission() {
    const statusReceived: PushNotificationStatus = Notification.permission as PushNotificationStatus;
    if (statusReceived === PushNotificationStatus.DENIED) {
      return;
    }

    if (statusReceived === PushNotificationStatus.GRANTED) {
      this.getToken();
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission !== PushNotificationStatus.GRANTED) {
        return;
      }
      this.getToken();
    });

  }

  async registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.error(err)
          console.error('Service Worker registration failed:', err);
        });
    }
  }

  async getToken() {
    try {
      const messaging = getMessaging();
      const newToken = await getToken(messaging, { vapidKey: this.vapidKey });
      
      if(!newToken) throw 'No Token registered';
      
      console.info('Registration token registered');
      this.currentToken.next(newToken);

      const createTokenRequest = {
        token: newToken,
        userId: this.currUserId,
        notificationType: PlatformType.WEB
      } as StoreNotificationTokenRequest;

      await firstValueFrom(this.storeNotificationToken(createTokenRequest))
  
    } catch (error) {
      console.error('Error getting token:', error);
    }      
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      const statusReceived: PushNotificationStatus = Notification.permission as PushNotificationStatus;
      if (statusReceived === PushNotificationStatus.GRANTED) {
        if(!payload.data) return;
        
        const notificationTitle = payload.data.title as string;
        const notificationOptions = {
          body: payload.data.body,
          // icon: payload.data.image,
        };

        new Notification(notificationTitle, notificationOptions);

      } else {
        console.error('Notification permission not granted.');
      }
    });
  }

  findUniqueNotificationToken(request: FindUniqueNotificationTokenRequest): Observable<NotificationToken | null> {
    type ResultType = {
      findUniqueNotificationToken: NotificationToken
    };

    const query = gql`
      query FindUniqueNotificationToken($request: FindUniqueNotificationTokenRequest!) {
        findUniqueNotificationToken(request: $request) {
          created
          token
        }
      }
    `;

    const item: QueryRef<ResultType> = this.apollo.watchQuery<ResultType>({
      query,
      variables: {
        request,
      },
      fetchPolicy: 'network-only',
    }); 

    try {
      return item.valueChanges.pipe(
        take(1),
        map((result: FetchResult<ResultType, Record<string, any>, Record<string, any>>) => {
          if (result.data) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          return result.data ? result.data.findUniqueNotificationToken : null;
        })
      );  
    } catch (error) {
      console.error('findUniqueNotificationToken Error',error)
      throw error;
    }
  }

  storeNotificationToken(
    request: StoreNotificationTokenRequest
  ): Observable<NotificationToken | null> {
    type ResultType = { storeNotificationToken: NotificationToken | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation storeNotificationToken($request: StoreNotificationTokenRequest!) {
            storeNotificationToken(request: $request) {
              token
              userId
              deleted
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(map((result) => result.data?.storeNotificationToken || null));
  }

  deleteNotificationToken(
    request: DeleteNotificationTokenRequest
  ): Observable<NotificationToken | null> {
    type ResultType = { deleteNotificationToken: NotificationToken | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation deleteNotificationToken($request: DeleteNotificationTokenRequest!) {
            deleteNotificationToken(request: $request) {
              token
              deleted
              userId
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(map((result: any) => result.data?.deleteNotificationToken || null));
  }

  findAllNotificationTokens(
    request: FindAllNotificationTokensRequest
  ): Observable<NotificationToken[] | null> {
    type ResultType = { findAllNotificationTokens: NotificationToken[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findAllNotificationTokens($request: FindAllNotificationTokensRequest!) {
        findAllNotificationTokens(request: $request) {
          deleted
          userId
          token
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllNotificationTokens || null;
          }
        )
      );
  }
}
import { Injectable } from '@angular/core';
import { ApolloError, ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ResetProjectApisList } from '@ih/app/client/project/api/list/data-access';
import { ResetProjectDashboardsList } from '@ih/app/client/project/dashboards/list/data-access';
import { ResetProjectFormsList } from '@ih/app/client/project/forms/list/data-access';
import { ResetProjectBoardsList } from '@ih/app/client/project/manager/list/data-access';
import { ResetProjectStorageList } from '@ih/app/client/project/storage/browse/data-access';
import { ResetProjectTablesList } from '@ih/app/client/project/tables/list/data-access';
import { ResetProjectUsersList } from '@ih/app/client/project/users/list/data-access';
import { ResetProjectWikisList } from '@ih/app/client/project/wiki/list/data-access';
import { Project } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, map, take } from 'rxjs';
import { GetProject, ResetStates, SetProject } from '../actions';
import { ErrorHandlerService } from '@ih/app/client/shared/services';

export interface ProjectStateModel {
  project: Project | null;
}

@State<ProjectStateModel>({
  name: 'project',
  defaults: {
    project: null,
  },
})
@Injectable()
export class ProjectState {
  @Selector()
  public static getState(state: ProjectStateModel) {
    return state;
  }

  @Selector()
  public static project(state: ProjectStateModel): Project | null {
    if(!state || !state.project) return null;
    return state.project;
  }

  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly errorHandlerService : ErrorHandlerService
  ) {}

  @Action(GetProject)
  public getProjecct(ctx: StateContext<ProjectStateModel>, { id }: GetProject) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueProject($id: String!) {
        findUniqueProject(request: { id: $id }) {
          id
          name
          template
          slug
          visibility
          description
          photoURL
          photoRef
          organisationLabel {
            id
            name
            slug
            visibility
          }
          groupLabel {
            id
            name
            slug
            visibility
          }
          user {
            id
            displayName
            username
          }
          # dashboards {
          #   id
          # }
          # forms {
          #   id
          # }
          # tables {
          #   id
          # }
          # pages {
          #   id
          # }
          # apis {
          #   id
          # }
          # boards {
          #   id
          # }
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findUniqueProject: Project }>({
        query,
        variables: {
          id,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<{ findUniqueProject: Project }>) => {
          this.store.dispatch(new ResetStates());
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          const project = result?.data?.findUniqueProject as Project;
          ctx.dispatch(new SetProject(project));
        }),
        catchError(async (error) => {
          const err = error as ApolloError;          
          this.errorHandlerService.handle(err);
        })
      );
  }

  @Action(SetProject)
  public setProject(
    ctx: StateContext<ProjectStateModel>,
    { project }: SetProject
  ) {
    ctx.setState(
      produce((draft) => {
        draft.project = project;
      })
    );
  }

  @Action(ResetStates)
  public resetStates(ctx: StateContext<ProjectStateModel>) {
    // Call child reset state action
    // currently only doing it on lists
    ctx.dispatch([
      new ResetProjectUsersList(),
      new ResetProjectTablesList(),
      new ResetProjectWikisList(),
      new ResetProjectBoardsList(),
      new ResetProjectFormsList(),
      new ResetProjectDashboardsList(),
      new ResetProjectApisList(),
      new ResetProjectStorageList(),
    ]);
  }
}

import { Component, Input } from '@angular/core';
import { AppClientSharedComponentsNotificationsFeatureComponent } from '@ih/app/client/shared/components/notifications/feature';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ManuallySetStatus } from '@ih/app/client/shared/states';
import { OfflineInfoHeaderPopoverComponent } from './offline-info-header-popover';

@Component({
    selector: 'ih-app-client-shared-components-menu-header-ui',
    template: `
    <ion-header>
      <ion-toolbar class="safe-padding">
        <ion-item lines="none">
          <ng-container *ngIf="customLogo || customLogoDark">
            <img
              class="logo-light"
              alt="Custom Logo"
              [src]="customLogo ? customLogo : customLogoDark"
            />
            <!-- TODO: Change to dark once added to config -->
            <img
              class="logo-dark"
              alt="Custom Logo"
              [src]="customLogoDark ? customLogoDark : customLogo"
            />
          </ng-container>
          <!-- TODO: Add some kind of delay so it wont show default until it is sure there is no custom logo -->
          <ng-container *ngIf="!customLogo && !customLogoDark">
            <a [routerLink]="['/home']" slot="start">
              <img
                class="logo-light"
                alt="Information Hub Logo"
                src="/assets/ih_logo_primary_no_up.svg"
              />
              <img
                class="logo-dark"
                alt="Information Hub Logo"
                src="/assets/ih_logo_white_no_up.svg"
              />
            </a>
          </ng-container>
        </ion-item>
        <ng-container *ngIf="sharedForm">
          <ion-buttons slot="end">
            <!-- <ion-button (click)="presentNotificationPopover($event)">
              <ion-icon
                name="notifications-outline"
                [color]="notificationCount > 0 ? 'primary' : ''"
              ></ion-icon>
              <ion-badge *ngIf="notificationCount > 0">{{
                notificationCount
              }}</ion-badge>
            </ion-button> -->
            <ion-button
              *ngIf="!networkStatus"
              (click)="toggleOffline(true)"
            >
              <ion-icon color="warning" name="wifi" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="networkStatus"
              (click)="toggleOffline(false)"
            >
              <ion-icon color="success" name="wifi" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ion-toolbar>
    </ion-header>
    <ion-header *ngIf="!networkStatus && sharedForm">
      <ion-item lines="none" color="warning">
        <ion-label slot="end">
          <ion-text>You are currently offline</ion-text>
        </ion-label>
        <ion-icon
          slot="end"
          (click)="presentOfflineInfoPopover($event)"
          name="alert-circle-outline"
        ></ion-icon>
      </ion-item>
    </ion-header>
  `,
    styles: [
        `
      img {
        height: 50px;
      }
    `,
    ],
    standalone: false
})
export class AppClientSharedComponentsMenuHeaderUiComponent{

  @Input() customLogo!: string | undefined | null;
  @Input() customLogoDark!: string | undefined | null;
  @Input() networkStatus!: boolean | unknown;
  @Input() sharedForm!: boolean;

  notificationCountSub$!: Subscription;


  constructor(private popover: PopoverController, private store: Store) {}


  async toggleOffline(status: boolean) {
    this.store.dispatch(new ManuallySetStatus(status));
  }

  async presentOfflineInfoPopover(ev: Event) {
    const popover = await this.popover.create({
      component: OfflineInfoHeaderPopoverComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    await popover.present();
  }

  async presentNotificationPopover(ev: Event) {
    const popover = await this.popover.create({
      component: AppClientSharedComponentsNotificationsFeatureComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    await popover.present();
  }
}

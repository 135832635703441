import { Component } from '@angular/core';

@Component({
    selector: 'ih-offline-info-header-popover',
    template: `
    <ion-card-content>
      Offline.<br />
      You can submit forms but will have to manually synced through the jobs page when you have internet connection.
    </ion-card-content>
  `,
    styles: [],
    standalone: false
})
export class OfflineInfoHeaderPopoverComponent {}

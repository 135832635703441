import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  Column,
  CreateDashboardComponentRequest,
  CreateDashboardRequest,
  Dashboard,
  DashboardComponent,
  DashboardComponentType,
  Datasource,
  DeleteDashboardComponentRequest,
  DeleteDashboardRequest,
  FindAllColumnsRequest,
  FindAllDashboardComponentsRequest,
  FindAllDashboardsRequest,
  FindAllDatasourcesRequest,
  FindUniqueDashboardRequest,
  QueryTableRequest,
  QueryTableResponse,
  RowData,
  UpdateDashboardComponentRequest,
  UpdateDashboardRequest,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

interface Filter {
  value: string;
  columnFixedName: string;
  datasourceId: string;
  componentId: string;
}
@Injectable()
export class DashboardsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  //Dashboards
  createDashboard(
    request: CreateDashboardRequest
  ): Observable<Dashboard | null> {
    type ResultType = { createDashboard: Dashboard | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation CreateDashboard($request: CreateDashboardRequest!) {
            createDashboard(request: $request) {
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(map((result: any) => result.data?.createDashboard || null));
  }

  deleteDashboard(
    request: DeleteDashboardRequest
  ): Observable<Dashboard | null> {
    type ResultType = { deleteDashboard: Dashboard | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteDashboard($request: DeleteDashboardRequest!) {
            deleteDashboard(request: $request) {
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(map((result: any) => result.data?.deleteDashboard || null));
  }

  findUniqueDashboard(
    request: FindUniqueDashboardRequest
  ): Observable<Dashboard | null> {
    type ResultType = { findUniqueDashboard: Dashboard | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueDashboard($request: FindUniqueDashboardRequest!) {
        findUniqueDashboard(request: $request) {
          id
          deleted
          projectId
          name
          created
          updated
          allowSharing
          visibility
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueDashboard || null;
          }
        )
      );
  }

  findAllDashboards(
    request: FindAllDashboardsRequest
  ): Observable<Dashboard[] | null> {
    type ResultType = { findAllDashboards: Dashboard[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllDashboards($request: FindAllDashboardsRequest!) {
        findAllDashboards(request: $request) {
          id
          name
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllDashboards || null;
          }
        )
      );
  }

  updateDashboard(
    request: UpdateDashboardRequest
  ): Observable<Dashboard | null> {
    type ResultType = { updateDashboard: Dashboard | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdateDashboard($request: UpdateDashboardRequest!) {
            updateDashboard(request: $request) {
              name
              visibility
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(map((result: any) => result.data?.updateDashboard || null));
  }

  // DashboardComponents
  createDashboardComponent(
    request: CreateDashboardComponentRequest
  ): Observable<DashboardComponent | null> {
    type ResultType = { createDashboardComponent: DashboardComponent | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation CreateDashboardComponent(
            $request: CreateDashboardComponentRequest!
          ) {
            createDashboardComponent(request: $request) {
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map((result: any) => result.data?.createDashboardComponent || null)
      );
  }

  // datasources
  findAllDatasources(
    request: FindAllDatasourcesRequest
  ): Observable<Datasource[] | null> {
    type ResultType = {
      findAllDatasources: Datasource[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindDashboardDatasources($request: FindAllDatasourcesRequest!) {
        findAllDatasources(request: $request) {
          id
          name
          type
          configuration
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllDatasources || null;
          }
        )
      );
  }

  // components
  findDashboardComponents(
    request: FindAllDashboardComponentsRequest
  ): Observable<DashboardComponent[] | null> {
    type ResultType = {
      findAllDashboardComponents: DashboardComponent[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllDashboardComponents(
        $request: FindAllDashboardComponentsRequest!
      ) {
        findAllDashboardComponents(request: $request) {
          id
          name
          type
          configuration
          dashboard {
            id
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            let components = result.data?.findAllDashboardComponents || null;

            if (components) {
              const componentsTemp = [...components];
              // sort the components of same type by name
              componentsTemp.sort((a, b) => {
                if (a.type === b.type) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
              });
              // move the map component with type map to the end of the array
              const mapComponents: DashboardComponent[] | null = componentsTemp.filter(
                (component) => component.type === 'MAP'
              );
              
              if (mapComponents && mapComponents.length > 0) {
                for (const mapComponent of mapComponents) {
                  const index = componentsTemp.indexOf(mapComponent);
                  componentsTemp.splice(index, 1);
                  componentsTemp.push(mapComponent);
                }
              }
              components = componentsTemp;

            }

            return components || null;
          }
        )
      );
  }

  queryTable(
    request: QueryTableRequest
  ): Observable<QueryTableResponse | null> {
    type ResultType = { queryTable: QueryTableResponse };
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query QueryTable($request: QueryTableRequest!) {
        queryTable(request: $request) {
          rows {
            columns {
              key
              value
            }
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.queryTable || null;
          }
        )
      );
  }

  findAllColumns(request: FindAllColumnsRequest): Observable<Column[] | null> {
    type ResultType = { findAllColumns: Column[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllColumns($request: FindAllColumnsRequest!) {
        findAllColumns(request: $request) {
          id
          name
          description
          dataType
          required
          primaryKey
          unique
          autoIncrement
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllColumns || null;
          }
        )
      );
  }

  deleteDashboardComponent(
    request: DeleteDashboardComponentRequest
  ): Observable<DashboardComponent | null> {
    type ResultType = { deleteDashboardComponent: DashboardComponent | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteDashboardComponent(
            $request: DeleteDashboardComponentRequest!
          ) {
            deleteDashboardComponent(request: $request) {
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map((result: any) => result.data?.deleteDashboardComponent || null)
      );
  }

  updateDashboardComponent(
    request: UpdateDashboardComponentRequest
  ): Observable<DashboardComponent | null> {
    type ResultType = { updateDashboardComponent: DashboardComponent | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdateDashboardComponent(
            $request: UpdateDashboardComponentRequest!
          ) {
            updateDashboardComponent(request: $request) {
              id
              name
              configuration
              type
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map((result: any) => {
          return result.data?.updateDashboardComponent || null;
        })
      );
  }

  // helper functions for dashboardComponent data

  filter(
    result: QueryTableResponse | null,
    configJson: any,
    filter: DashboardComponent
  ): JSON {
    const columnName: string = configJson['layers'][0]['datasourceConfig'][
      'columns'
    ][0]['columnFixedName'].replace(/[^\w\s\\]/gi, '');
    const datasourceId: string =
      configJson['layers'][0]['datasourceConfig']['datasourceId'];
    const dataRows = result?.rows || [];
    const data: (string | undefined | null)[] =
      [
        ...new Set(
          dataRows.flatMap((row) => row?.columns?.flatMap((col) => col?.value))
        ),
      ];
    const filters: Filter[] = [];

    data.forEach((element) => {
      if (element === null)
        filters.push({
          value: 'NULL',
          columnFixedName: columnName,
          datasourceId: datasourceId,
          componentId: filter.id,
        });
      else
        filters.push({
          value: element || '',
          columnFixedName: columnName,
          datasourceId: datasourceId,
          componentId: filter.id,
        });
    });

    const ret = JSON.parse(
      '{"name":"' + columnName + '","data":' + JSON.stringify(filters) + '}'
    );
    return ret;
  }

  lineBarSeries(result: QueryTableResponse | null, configJson: any): JSON {
    const dataRows: RowData[] = (result?.rows as RowData[]) || [];
    const series: { name: string; data: number[] }[] = [];
    const chartColumns = configJson['layers'][0]['datasourceConfig'][
      'columns'
    ] as {
      columnName: string;
      columnFixedName: string;
      columnId: string;
      use: string;
    }[];
    let categories: string[] = [];
    let newSeriesDataPoint: [string, number][] = [];
    let seriesDataPoint: number[] = [];
    let value: number[] = [];

    // to create series for each dashboardComponent column added
    chartColumns.forEach((dashboardComponentData) => {
      if (dashboardComponentData.use != 'dimension') {
        const dataPoints = this.iterateDataRows(
          dataRows,
          configJson,
          'LineBar',
          dashboardComponentData
        );

        newSeriesDataPoint = [];
        newSeriesDataPoint = dataPoints.dataPoints as [string, number][];
        value = [];
        // For each category apply the aggregate to the data columns
        categories = dataPoints.categories.sort((a, b) => {
          const aNum = Number(a) || a;
          const bNum = Number(b) || b;

          if (typeof aNum === 'string' && typeof bNum === 'string') {
            return aNum.localeCompare(bNum);
          }
          
          if (typeof aNum === 'number' && typeof bNum === 'number') {
            return aNum - bNum;
          }
          if (typeof aNum === 'string' && typeof bNum === 'number') {
            return 1;
          }
          if (typeof aNum === 'number' && typeof bNum === 'string') {
            return -1;
          }
          return 0;
        });

        categories.forEach((category) => {
          seriesDataPoint = [];

          // populate seriesDataPoint with the series data for the category
          newSeriesDataPoint.map((dataPoint) => {
            // Check if data point belongs to current category
            if (dataPoint[0] === category) seriesDataPoint.push(dataPoint[1]);
          });

          // do calculation on the data of the category
          value.push(
            this.doCalculation(
              configJson['layers'][0]['layerConfig']['aggregate'],
              seriesDataPoint
            )
          );
        });
        series.push({
          name: dashboardComponentData.columnName,
          data: value,
        });
      }
    });
    return JSON.parse(
      '{"series":' +
        JSON.stringify(series) +
        ',"xAxis":' +
        JSON.stringify(categories) +
        '}'
    );
  }

  mapSeries(
    result: QueryTableResponse | null | undefined,
    configJson: any | null,
    layer: any,
    dashboardComponent: DashboardComponent
  ): JSON {
    const dataRows = result?.rows || [];
    const mapType: string = layer['layerType'];
    const layerName: string = layer['layerName'];

    if (
      dashboardComponent.type === DashboardComponentType.MAP &&
      layer &&
      layer['layerType'] === 'kml'
    ) {
      return JSON.parse(
        '{"layerData":[{"url":"' +
          layer['datasourceConfig']['url'] +
          '"}],' +
          '"mapType":"' +
          mapType +
          '","layerName":"' +
          layerName +
          '"}'
      );
    }

    interface Column {
      columnFixedName: string;
      columnId: string;
      use: string;
    }

    let latitude: Column | null = null;
    let longitude: Column | null = null;
    const information: Column[] = [];
    let image: Column | null = null;

    layer.datasourceConfig.columns.forEach((column: Column) => {
      if (column.use === 'latitude') latitude = column;

      if (column.use === 'longitude') longitude = column;

      if (column.use === 'information') information.push(column);

      if (column.use === 'image') image = column;
    });

    let mapData = '';

    if (dashboardComponent.data && layer && layer['layerType'] === 'pointMap') {
      dataRows.map((dataRow) => {
        let lat: number | null = null;
        let lng: number | null = null;
        let inf: string | null = null;
        const infCols: { key: string; value: string }[] = [];
        let img: string | null = null;

        // const infCol

        dataRow?.columns?.forEach((column) => {
          if (column?.key === latitude?.columnFixedName) {
            if (column?.value != null) {
              lat = Number(column?.value);
            }
          }
          if (column?.key === longitude?.columnFixedName) {
            if (column?.value != null) {
              lng = Number(column?.value);
            }
          }

          const infCol = information.find(
            (infColumn) => infColumn?.columnFixedName === column?.key
          );

          if (infCol) {
            if (column?.value != null) {
              infCols.push({ key: column?.key, value: column?.value });
            }
          }
          if (column?.key === image?.columnFixedName) {
            if (column?.value != null) {
              img = String(column?.value);
            }
          }
          // TODO: Add marker title
        });

        inf = JSON.stringify(infCols);

        if (lat && lng && inf && img) {
          mapData +=
            '{"lat":' +
            lat +
            ',"lng":' +
            lng +
            ',"inf":' +
            inf +
            ',"img":"' +
            img +
            '"},';
        } else if (lat && lng && inf) {
          mapData += '{"lat":' + lat + ',"lng":' + lng + ',"inf":' + inf + '},';
        } else if (lat && lng && img) {
          mapData +=
            '{"lat":' + lat + ',"lng":' + lng + ',"img":"' + img + '"},';
        } else if (lat && lng) {
          mapData += '{"lat":' + lat + ',"lng":' + lng + '},';
        }
      });
      const newLayer = JSON.parse(
        '{"layerData":[' +
          mapData.substring(0, mapData.length - 1) +
          '],' +
          '"mapType":"' +
          mapType +
          '","layerName":"' +
          layerName +
          '"}'
      );

      return newLayer;
    } else if (
      dashboardComponent.data &&
      layer &&
      layer['layerType'] === 'heatmap'
    ) {
      dataRows.map((dataRow) => {
        let lat: number | null = null;
        let lng: number | null = null;

        dataRow?.columns?.forEach((column) => {
          if (column?.key === latitude?.columnFixedName) {
            if (column?.value != null) {
              lat = Number(column?.value);
            }
          }
          if (column?.key === longitude?.columnFixedName) {
            if (column?.value != null) {
              lng = Number(column?.value);
            }
          }
        });

        if (lat && lng) {
          mapData += '{"lat":' + lat + ',"lng":' + lng + '},';
        }
      });
      const newLayer = JSON.parse(
        '{"layerData":[' +
          mapData.substring(0, mapData.length - 1) +
          '],' +
          '"mapType":"' +
          mapType +
          '","layerName":"' +
          layerName +
          '"}'
      );

      return newLayer;
    } else {
      dataRows.map((dataRow) => {
        const lat: number | null = null;
        const lng: number | null = null;

        if (lat && lng) {
          mapData += '{"lat":' + lat + ',"lng":' + lng + '},';
        }
      });
      return JSON.parse(
        '{"layers":[{"layerData":[' +
          mapData.substring(0, mapData.length - 1) +
          '],' +
          '"kmlLayers":' +
          JSON.stringify(configJson['kmlLayers']) +
          ',"mapType":"' +
          mapType +
          '"}]}'
      );
    }
  }

  scatterSeries(result: QueryTableResponse | null, configJson: any): JSON {
    const dataRows: RowData[] = (result?.rows as RowData[]) || [];
    let seriesDataPoint: [number, number][] = [];
    let newSeriesDataPoint: [string, number, number][] = [];
    const series: { name: string; data: [number, number][] }[] = [];

    const dataPoints = this.iterateDataRows(
      dataRows,
      configJson,
      'Scatter',
      null
    );
    newSeriesDataPoint = dataPoints.dataPoints as [string, number, number][];
    // for each category create a corresponding series of data
    dataPoints.categories.forEach((category) => {
      seriesDataPoint = [];
      newSeriesDataPoint.map((dataPoint) => {
        if (dataPoint[0] === category)
          seriesDataPoint.push([dataPoint[1], dataPoint[2]]);
      });
      series.push({ name: category, data: seriesDataPoint });
    });

    series.sort((a, b) => {
      const aNum = Number(a.name) || a.name;
      const bNum = Number(b.name) || b.name;

      if (typeof aNum === 'string' && typeof bNum === 'string') {
        return aNum.localeCompare(bNum);
      }
      
      if (typeof aNum === 'number' && typeof bNum === 'number') {
        return aNum - bNum;
      }
      if (typeof aNum === 'string' && typeof bNum === 'number') {
        return 1;
      }
      if (typeof aNum === 'number' && typeof bNum === 'string') {
        return -1;
      }
      return 0;
    });

    return JSON.parse('{"series":' + JSON.stringify(series) + '}');
  }

  scoreboardSeries(result: QueryTableResponse | null, configJson: any): JSON {
    const columnName: string =
      configJson['layers'][0]['datasourceConfig']['columns'][0][
        'columnFixedName'
      ];
    let value = 0;
    const dataRows = result?.rows || [];
    const data: any[] = [];

    dataRows.map((dataRow) => {
      dataRow?.columns?.forEach((column: any) => {
        if (column.value != null) data.push(column.value);
      });
    });

    value = this.doCalculation(
      configJson['layers'][0]['layerConfig']['aggregate'],
      data
    );

    return JSON.parse('{"name":"' + columnName + '","data":' + value + '}');
  }

  tableData(
    result: QueryTableResponse | null,
    configJson: any,
    columns: Column[]
  ): JSON {
    const dataRows: RowData[] = (result?.rows as RowData[]) || [];
    const chartColumns = configJson['layers'][0]['datasourceConfig'][
      'columns'
    ] as {
      columnName: string;
      columnFixedName: string;
      columnId: string;
      use: string;
    }[];

    const columnIds: string[] =
      chartColumns.map((column) => column.columnId) || [];

    const filterdColumns: Column[] = [];
    columnIds.map((columnId) => {
      const col = columns.find((column) => column.id === columnId);
      if (col) filterdColumns.push(col);
    });

    columns?.filter((column) => columnIds.includes(column.id));

    const cols: { key: string }[] = [];

    chartColumns.forEach((dashboardComponentData) => {
      if (dashboardComponentData.use !== 'user') {
        cols.push({ key: dashboardComponentData.columnFixedName });
      }
    });

    return JSON.parse(
      '{"columns":' +
        JSON.stringify(filterdColumns) +
        ',  "rows":' +
        JSON.stringify(dataRows) +
        '}'
    );
  }

  private doCalculation(
    calculationType: string,
    calculationData: any[]
  ): number {
    let value = 0;

    if (calculationType === 'average') {
      value =
        calculationData.reduce((a, b) => Number(a) + Number(b)) /
        calculationData.length;
    } else if (calculationType === 'sum') {
      value = calculationData.reduce((a, b) => Number(a) + Number(b));
    } else if (calculationType === 'count') {
      value = calculationData.length;
    } else if (calculationType === 'count distinct') {
      value = new Set(calculationData).size;
    }

    value = Number(value.toPrecision(6));

    return value;
  }

  iterateDataRows(
    dataRows: RowData[],
    configJson: any,
    type: string,
    lineBarChartData: {
      columnName: string;
      columnId: string;
      columnFixedName: string;
      use: string;
    } | null
  ):
    | { dataPoints: [string, number, number][]; categories: string[] }
    | { dataPoints: [string, number][]; categories: string[] } {
    const lineBarNewSeriesDataPoint: [string, number][] = [];
    const scatterNewSeriesDataPoint: [string, number, number][] = [];
    const categories: string[] = [];

    dataRows.forEach((dataRow) => {
      // populate the category array
      if (type === 'Scatter') {
        //
        const dataPoints = this.buildDataPoint(dataRow, configJson, type, null);
        if (dataPoints) {
          scatterNewSeriesDataPoint.push(
            dataPoints as [category: string, x: number, y: number]
          );
          if (!categories.includes(dataPoints[0], 0)) {
            categories.push(dataPoints[0]);
          }
        }
      } else {
        const dataPoints = this.buildDataPoint(
          dataRow,
          configJson,
          type,
          lineBarChartData
        );
        if (dataPoints) {
          lineBarNewSeriesDataPoint.push(
            dataPoints as [category: string, y: number]
          );
          if (!categories.includes(dataPoints[0], 0)) {
            categories.push(dataPoints[0]);
          }
        }
      }
    });

    if (type === 'Scatter')
      return { dataPoints: scatterNewSeriesDataPoint, categories };
    else return { dataPoints: lineBarNewSeriesDataPoint, categories };
  }

  private buildDataPoint(
    rowData: RowData,
    configJson: any,
    type: string,
    lineBarChartData: {
      columnName: string;
      columnId: string;
      columnFixedName: string;
      use: string;
    } | null
  ):
    | [category: string, x: number, y: number]
    | [category: string, y: number]
    | null {
    const colName = lineBarChartData
      ? lineBarChartData['columnFixedName'].replace(/[^\w\s\\]/gi, '')
      : '';
    let x: number | null = null;
    let y: number | null = null;
    let category = '';
    const columns: {
      columnName: string;
      columnFixedName: string;
      columnId: string;
      use: string;
    }[] = configJson['layers'][0]['datasourceConfig']['columns'];

    // also used for scatter xAxis
    const chartDimensionColumn = columns.find((column: any) => {
      return column.use === 'dimension' || column.use === 'category';
    });
    const categoryName = chartDimensionColumn?.columnFixedName.replace(
      /[^\w\s\\]/gi,
      ''
    );

    let yAxisColumn: {
      columnName: string;
      columnFixedName: string;
      columnId: string;
      use: string;
    } = {
      columnName: '',
      columnFixedName: '',
      columnId: '',
      use: '',
    };

    let xAxisColumn: {
      columnName: string;
      columnFixedName: string;
      columnId: string;
      use: string;
    } = {
      columnName: '',
      columnFixedName: '',
      columnId: '',
      use: '',
    };

    if (type === 'Scatter') {
      yAxisColumn = columns.find((column: any) => {
        return column.use === 'yAxis';
      }) || {
        columnName: '',
        columnFixedName: '',
        columnId: '',
        use: '',
      };

      yAxisColumn.columnFixedName = yAxisColumn.columnFixedName.replace(
        /[^\w\s\\]/gi,
        ''
      );

      xAxisColumn = columns.find((column: any) => {
        return column.use === 'xAxis';
      }) || {
        columnName: '',
        columnFixedName: '',
        columnId: '',
        use: '',
      };
    }

    xAxisColumn.columnFixedName = xAxisColumn.columnFixedName.replace(
      /[^\w\s\\]/gi,
      ''
    );

    rowData.columns?.forEach((column) => {
      // populate categories array
      if (column?.key === categoryName && type === 'Scatter') {
        if (column?.value != null) {
          category = String(column?.value);
        }
      } else if (column?.key === categoryName) {
        if (column?.value != null) {
          category = String(column?.value);
        }
      }
      // populate the xAxis array if dashboardComponent type is a scatter dashboardComponent
      if (column?.key === xAxisColumn.columnFixedName && type === 'Scatter') {
        if (column?.value != null) {
          x = Number(column?.value);
        }
      }
      // populate the yAxis array
      if (column?.key === yAxisColumn.columnFixedName && type === 'Scatter') {
        if (column?.value != null) {
          y = Number(column?.value);
        }
      }
      // because of different configurations
      else if (column?.key === colName) {
        if (column?.value != null) {
          y = Number(column?.value);
        }
      }
    });
    if (x!==null && y!==null) {
      return [category, x, y];
    }
    else if (y) {
      return [category, y];
    } else{
      return null;
    }
  }
}

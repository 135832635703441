import { ApolloQueryResult } from '@apollo/client/core';
import { FindAllProjectMembersResponse } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectsSuccess {
  public static readonly type = '[User/Projects] FindAllProjectsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjectsForUser: FindAllProjectMembersResponse;
    }>
  ) {}
}

import { Injectable } from '@angular/core';
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  listAll,
  ListResult,
  ref,
  Storage,
  uploadBytesResumable,
  UploadTask,
} from '@angular/fire/storage';
import { ExtractZipRequest } from '@ih/app/shared/apis/interfaces';
import { Apollo, gql } from 'apollo-angular';
import JSZip from 'jszip';
import {  Observable,from, lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(
    private readonly apollo: Apollo,
    private readonly afs: Storage,
  ) {}

  listAll(_ref: string): Observable<ListResult> {
    const temp = ref(this.afs, _ref);
    return from(listAll(temp));
  }

  ref(_ref: string) {
    return ref(this.afs, _ref);
  }

  delete(_ref: string) {
    const temp = ref(this.afs, _ref);
    return from(deleteObject(temp));
  }

  async deleteFolder(folderPath: string) {
    const listAllFiles = async (folderPath: string) => {
      const folderRef = ref(this.afs, folderPath);
      const folder = await listAll(folderRef);

      const folderPromises = folder.prefixes.map(async (subFolder) => {
        await this.deleteFolder(subFolder.fullPath);
      });
      const deletePromises = folder.items.map(async (item) => {
        const fileRef = ref(this.afs, item.fullPath);
        await deleteObject(fileRef);
      });
    
      await Promise.all([...deletePromises, ...folderPromises]);
    };
  
    await listAllFiles(folderPath);
  }

  async downloadFolder(name : string, _ref: string) {
    const jszip = new JSZip();
    const folder = await (listAll(ref(this.afs, _ref)));
    const promises = folder.items
    .map(async (item) => {
      if(item.name==='KEEPME.md') return;
      const file = await getMetadata(item);
      const fileRef = ref(this.afs, item.fullPath);
      const fileBlob = await getDownloadURL(fileRef).then((url) => {
        return fetch(url).then((response) => response.blob());
      });
      jszip.file(file.name, fileBlob);
    })
    .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
    await promises;

    jszip.generateAsync({ type: 'blob' }).then((content) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `${name}.zip`;
      link.click();
    });
  }

  async renameFolder(path: string, newName: string) {
   type ResultType = { renameFolder : boolean; };

   const query = gql`
    mutation renameFolder($request: RenameFolderRequest!) {
      renameFolder(request: $request){
        fullPath
      }
    }`;

    const value = await lastValueFrom(this.apollo.mutate<ResultType>({
      mutation: query,
      variables: {
        request: {
          path,
          newName,
        },
      },
    }));

    if(!value.data) throw new Error('Folder rename failed');
    return value.data.renameFolder;
  }

  //This will be done server side
   async extractZip(request : ExtractZipRequest) : Promise<string> {
    type ResultType = { extractZip : any; };


    const query = gql`
    mutation extractZip($request: ExtractZipRequest!) {
      extractZip(request: $request){
        fullPath
      }
    }
  `;

  const value = await lastValueFrom(this.apollo.mutate<ResultType>({
    mutation: query,
    variables: {
      request,
    },
  }))

  if(!value.data) throw new Error('Zip extraction failed');
  return value.data.extractZip.fullPath;
  }
  ;

  create(_ref: string): UploadTask {
    const temp = ref(this.afs, _ref);
    return uploadBytesResumable(temp, new Blob());
  }

    

  upload(_ref: string, file: File): UploadTask {
    const temp = ref(this.afs, _ref);
    return uploadBytesResumable(temp, file);
  }
}

import { ApolloQueryResult } from '@apollo/client/core';
import { FindAllProjectMembersResponse } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectMembersSuccess {
  public static readonly type =
    '[Project/ProjectMembers/List] FindAllProjectMembersSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjectMembers: FindAllProjectMembersResponse;
    }>
  ) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AppClientSharedComponentsMenuHeaderUiComponent } from './app-client-shared-components-menu-header-ui.component';
import { OfflineInfoHeaderPopoverModule } from "./offline-info-header-popover/offline-info-header-popover.module";

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, OfflineInfoHeaderPopoverModule],
  declarations: [AppClientSharedComponentsMenuHeaderUiComponent],
  exports: [AppClientSharedComponentsMenuHeaderUiComponent],
})
export class AppClientSharedComponentsMenuHeaderUiModule {}
